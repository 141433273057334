<template>
  <div class="content">404</div>
</template>

<script>
// import methods from "./error404.js";

export default {
  name: '404',
  data() {
    return {};
  },
  computed: {},
  created() {
    this.init();
  },
  //   methods: methods,
};
</script>

<style lang="scss" scoped></style>
